import React, { useState, useEffect } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import Switch from "react-switch";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import CryptoJS from "crypto-js";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setShowloginmodal } from "../Redux/modalSlice";
import Footer from "./Footer";
import { logoutUser } from "../Redux/authSlice";
import { setActiveSection } from "../Redux/activeSectionSlice";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setDarkmode } from "../Redux/darkModeSlice";
import { setActivePage } from "../Redux/PageSlice";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const darkMode = useSelector((state) => state.darkMode);

  // Decrypt local storage data
  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const userIdLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("userId") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const usernameLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("username") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const imageUrlLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("imageurl") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const profileLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("profile") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const showloginModal = useSelector((state) => state.modal.showLoginModal);
  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);
  const userIdRedux = useSelector((state) => state.auth.userId);
  const usernameRedux = useSelector((state) => state.auth.username);
  const imageUrlRedux = useSelector((state) => state.auth.imageUrl);
  const profileRedux = useSelector((state) => state.auth.profile);

  const isLoggedIn = isLoggedInLocal || isLoggedInRedux;
  const userId = userIdLocal || userIdRedux;
  const username = usernameLocal || usernameRedux;
  const imageUrl = imageUrlLocal || imageUrlRedux;
  const profile = profileLocal || profileRedux;

  const isFocus = document.hasFocus();

  useEffect(() => {
    if (isFocus) {
      dispatch(setActivePage(false));
      // Add your condition or action here
    }
  }, [isFocus]);

  // const [darkMode, setDarkMode] = useState(true);
  const [pushNotify, setPushNotify] = useState(false);
  const [allDrill, setAllDrill] = useState(false);
  const [drill50gm, setDrill50gm] = useState(false);
  const [drill100gm, setDrill100gm] = useState(false);
  const [potentialDis, setPotentialDis] = useState(false);
  const [allHalts, setAllHalts] = useState(false);
  const [exploHalts, setExploHalts] = useState(false);
  const [showDiscoveriesModal, setShowDiscoveriesModal] = useState(false);
  const [discoveriesEnabled, setDiscoveriesEnabled] = useState(potentialDis);
  const [showSaveButton, setShowSaveButton] = useState(isLoggedIn);
  const [capAlert, setCapAlert] = useState(false);
  const [capAlertModal, setCapAlertModal] = useState(false);
  const [capAlertEnabled, setCapAlertEnabled] = useState(capAlert);

  const [profileModal, setProfileModal] = useState(false);
  // const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Update meta tags when the component mounts
    document.title = "Settings - GoldDiscovery.com";

    const metaTitle = document.querySelector('meta[name="title"]');
    if (metaTitle) {
      metaTitle.content = "Settings - GoldDiscovery.com";
    }

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = "Settings - GoldDiscovery.com";
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = "Settings - GoldDiscovery.com";
    }

    // // Add or remove the darkmode class based on the darkMode state
    // const bodyElement = document.querySelector("body");
    // if (bodyElement) {
    //   if (darkMode) {
    //     bodyElement.classList.add("darkmode");
    //   } else {
    //     bodyElement.classList.remove("darkmode");
    //   }
    // }

    //     // Encrypt and save the dark mode state in localStorage
    // Store encrypted darkMode value in local storage
    // const encryptedDarkMode = CryptoJS.AES.encrypt(
    //   darkMode.toString(),
    //   SECRET_KEY
    // ).toString();
    // localStorage.setItem("darkMode", encryptedDarkMode);
    // dispatch(setDarkmode(darkMode));

    // Clean up when the component unmounts
    return () => {
      document.title = "GoldDiscovery.com";

      if (metaTitle) {
        metaTitle.content = "GoldDiscovery.com";
      }

      if (metaDescription) {
        metaDescription.content = "";
      }

      if (metaKeywords) {
        metaKeywords.content = "";
      }
    };
  }, []);

  // fetch Initial Notifications
  useEffect(() => {
    const fetchNoticationValues = async () => {
      try {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("settings", 1);

        const response = await BaseApi.post(url.appSettings, formData);
        // console.log("Notification InitialValue Api:", response);

        if (
          response &&
          response.data &&
          response.data.pushSettings &&
          response.data.pushSettings.length > 0
        ) {
          const pushSettings = response.data.pushSettings[0];
          // setDarkMode(pushSettings.theme === "1" ?? false);
          setPushNotify(pushSettings.pushNotification === "1" ?? false);
          setAllDrill(pushSettings.allDrillResult === "1" ?? false);
          setDrill50gm(pushSettings.fiftyAbove === "1" ?? false);
          setDrill100gm(pushSettings.hundredAbove === "1" ?? false);
          setPotentialDis(pushSettings.potentialDiscoveries === "1" ?? false);
          setAllHalts(pushSettings.allTradingHalts === "1" ?? false);
          setExploHalts(pushSettings.explorationHalts === "1" ?? false);
          setCapAlert(pushSettings.capAlerts === "1" ?? false);
        }
      } catch (error) {
        // console.error("Error fetching :", error);
      } finally {
      }
    };
    fetchNoticationValues();
  }, []);

  const fetchNoticationValues = async () => {
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("settings", 1);

      const response = await BaseApi.post(url.appSettings, formData);
      // console.log("Notification InitialValue Api:", response);

      if (
        response &&
        response.data &&
        response.data.pushSettings &&
        response.data.pushSettings.length > 0
      ) {
        const pushSettings = response.data.pushSettings[0];
        // setDarkMode(pushSettings.theme === "1" ?? false);
        setPushNotify(pushSettings.pushNotification === "1" ?? false);
        setAllDrill(pushSettings.allDrillResult === "1" ?? false);
        setDrill50gm(pushSettings.fiftyAbove === "1" ?? false);
        setDrill100gm(pushSettings.hundredAbove === "1" ?? false);
        setPotentialDis(pushSettings.potentialDiscoveries === "1" ?? false);
        setAllHalts(pushSettings.allTradingHalts === "1" ?? false);
        setExploHalts(pushSettings.explorationHalts === "1" ?? false);
        setCapAlert(pushSettings.capAlerts === "1" ?? false);
      }
    } catch (error) {
      // console.error("Error fetching mining news:", error);
    } finally {
    }
  };

  const handleLoginModalShow = () => {
    // setShowLoginModal(true);
    dispatch(setShowloginmodal(true));
    setShowSignupModal(false);
    setShowForgotPasswordModal(false);
  };

  useEffect(() => {
    // Check the state and trigger the handleLoginModalShow if true
    if (showloginModal) {
      handleLoginModalShow();
    }
  }, [showloginModal]);

  const handleSignupModalShow = () => {
    // setShowLoginModal(false);
    dispatch(setShowloginmodal(false));
    setShowSignupModal(true);
    setShowForgotPasswordModal(false);
  };

  const handleForgotPasswordModalShow = () => {
    // setShowLoginModal(false);
    dispatch(setShowloginmodal(false));
    setShowSignupModal(false);
    setShowForgotPasswordModal(true);
  };

  const handleModalClose = () => {
    dispatch(setShowloginmodal(false));
    // setShowLoginModal(false);
    dispatch(setShowloginmodal(false));
    setShowSignupModal(false);
    setShowForgotPasswordModal(false);
  };

  const handleBack = () => {
    // setShowLoginModal(true);
    dispatch(setShowloginmodal(false));
    setShowSignupModal(false);
    setShowForgotPasswordModal(false);
  };

  const handleSignupSuccess = () => {
    setShowSignupModal(false);
    // setShowLoginModal(true);
    dispatch(setShowloginmodal(false));
  };

  const handleDarkMode = (nextChecked) => {
    // setDarkMode(nextChecked);
    dispatch(setDarkmode(nextChecked));

    // Add or remove the 'darkmode' class based on the updated darkMode state
    const bodyElement = document.body;
    if (nextChecked) {
      bodyElement.classList.add("darkmode");
    } else {
      bodyElement.classList.remove("darkmode");
    }

    // // Encrypt and save the dark mode state in localStorage
    // const encryptedDarkMode = CryptoJS.AES.encrypt(
    //   nextChecked.toString(),
    //   SECRET_KEY
    // ).toString();
    // localStorage.setItem("darkMode", encryptedDarkMode);
  };

  // console.log("Redux Darkmode", darkMode);

  const handlePushNotify = (nextChecked) => {
    if (isLoggedIn) {
      setPushNotify(nextChecked);
      if (!nextChecked) {
        // If push notifications are disabled, turn off all switches
        setAllDrill(false);
        setDrill50gm(false);
        setDrill100gm(false);
        setPotentialDis(false);
        setAllHalts(false);
        setExploHalts(false);
        setCapAlert(false);
      }
    } else {
      // User is not logged in, show the login modal
      dispatch(setShowloginmodal(true));
    }
  };

  const handleAllDrill = (nextChecked) => {
    if (isLoggedIn) {
      setAllDrill(nextChecked);
      setDrill50gm(false);
      setDrill100gm(false);
      if (nextChecked) {
        setPushNotify(true);
      }
    } else {
      // User is not logged in, show the login modal
      dispatch(setShowloginmodal(true));
    }
  };

  const handleDrill50gm = (nextChecked) => {
    if (isLoggedIn) {
      setAllDrill(false);
      setDrill50gm(nextChecked);
      setDrill100gm(false);
      if (nextChecked) {
        setPushNotify(true);
      }
    } else {
      dispatch(setShowloginmodal(true));
    }
  };

  const handleDrill100gm = (nextChecked) => {
    if (isLoggedIn) {
      setAllDrill(false);
      setDrill50gm(false);
      setDrill100gm(nextChecked);
      if (nextChecked) {
        setPushNotify(true);
      }
    } else {
      dispatch(setShowloginmodal(true));
    }
  };

  // const handlePotentialDis = (nextChecked) => {
  //   if (isLoggedIn) {
  //     setPotentialDis(nextChecked);
  //     if (nextChecked) {
  //       setPushNotify(true);
  //     }
  //   } else {
  //     dispatch(setShowloginmodal(true));
  //   }
  // };

  const handlePotentialDis = (nextChecked) => {
    if (isLoggedIn) {
      if (!potentialDis && nextChecked) {
        setShowDiscoveriesModal(true);
      } else {
        setPotentialDis(nextChecked);
        if (nextChecked) {
          setPushNotify(true);
        }
      }
    } else {
      dispatch(setShowloginmodal(true));
    }
  };

  const handleCapAlert = (nextChecked) => {
    if (isLoggedIn) {
      if (!capAlert && nextChecked) {
        setCapAlertModal(true);
      } else {
        setCapAlert(nextChecked);
        if (nextChecked) {
          setPushNotify(true);
        }
      }
    } else {
      dispatch(setShowloginmodal(true));
    }
  };

  const handleAllHalts = (nextChecked) => {
    if (isLoggedIn) {
      setExploHalts(false);
      setAllHalts(nextChecked);
      if (nextChecked) {
        setPushNotify(true);
      }
    } else {
      dispatch(setShowloginmodal(true));
    }
  };

  const handleExploHalts = (nextChecked) => {
    if (isLoggedIn) {
      setAllHalts(false);
      setExploHalts(nextChecked);
      if (nextChecked) {
        setPushNotify(true);
      }
    } else {
      dispatch(setShowloginmodal(true));
    }
  };

  const handleDiscoveriesModalConfirm = (confirmed) => {
    setShowDiscoveriesModal(false); // Hide the modal
    if (confirmed) {
      // If confirmed, enable discoveries
      setPotentialDis(true);
      setPushNotify(true);
    } else {
      // If canceled, disable discoveries
      setDiscoveriesEnabled(false);
    }
  };

  const handleCapAlertModalConfirm = (confirmed) => {
    setCapAlertModal(false); // Hide the modal
    if (confirmed) {
      // If confirmed, enable discoveries
      setCapAlert(true);
      setPushNotify(true);
    } else {
      // If canceled, disable discoveries
      setCapAlertEnabled(false);
    }
  };

  const handleSubmit = () => {
    const pushSettings = {
      theme: darkMode ? 1 : 0,
      pushNotification: pushNotify ? 1 : 0,
      fiftyAbove: drill50gm ? 1 : 0,
      hundredAbove: drill100gm ? 1 : 0,
      allDrillResult: allDrill ? 1 : 0,
      potentialDiscoveries: potentialDis ? 1 : 0,
      allTradingHalts: allHalts ? 1 : 0,
      explorationHalts: exploHalts ? 1 : 0,
      capAlerts: capAlert ? 1 : 0,
    };

    const formdata = new FormData();
    formdata.append("userId", userId);
    formdata.append("pushSettings", JSON.stringify(pushSettings));

    BaseApi.post(url.notification_settings, formdata)
      .then((response) => {
        if (response && response.data) {
          // Check the API response status
          switch (response.data.status) {
            case "success":
              // Notification Success (toast success)
              toast.success(response.data.toast);
              // console.log("Notifications Success API", response);

              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
              console.warn("Unexpected response status:", response.data.status);
          }
        } else {
          // console.error("Unexpected response format:", response);
        }
      })
      .catch((error) => {
        // API request failed (toast error)
        toast.error("Request failed. Please try again later.");
        // console.log("Error:", error);
      });
  };

  useEffect(() => {
    setShowSaveButton(isLoggedIn);
  }, [isLoggedIn]);

  const handleProfileModal = () => {
    setProfileModal(!profileModal);
  };

  const handleProfilePage = () => {
    dispatch(setActiveSection("edit-profile"));
    navigate("/edit-profile");
    window.location.reload();
  };

  const handleLogout = () => {
    // Disable the submit button to prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const formdata = new FormData();
    formdata.append("user", userId);

    // console.log("formdata", userId);

    BaseApi.post(url.logout, formdata)
      .then((response) => {
        if (response && response.data) {
          // console.log(response);
          // Check the API response status
          switch (response.data.status) {
            case "success":
              // Successful Email Sent (toast success)
              toast.success(response.data.toast);

              dispatch(logoutUser());
              setProfileModal(false);

              // Clear the user-related data from storage
              localStorage.removeItem("isLoggedIn"); //1
              localStorage.removeItem("email"); //2
              localStorage.removeItem("userId"); //3
              localStorage.removeItem("username"); //4
              localStorage.removeItem("imageurl"); //5
              localStorage.removeItem("profile"); //6
              localStorage.removeItem("timezone"); //7

              // Force a re-render by updating state
              setShowSaveButton(false);

              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            case "danger":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
              console.warn("Unexpected response status:", response.data.status);
          }
        } else {
          // console.error("Unexpected response format:", response);
        }

        setIsSubmitting(false);
      })
      .catch((error) => {
        // API request failed (toast error)
        toast.error("Request failed. Please try again later.");
        // console.log("Error:", error);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setShowSaveButton(isLoggedIn || isLoggedInRedux);
  }, [isLoggedIn, isLoggedInRedux]);

  useEffect(() => {
    // Check the isLoggedIn state in Redux and trigger fetchNotificationValues
    if (isLoggedInRedux) {
      fetchNoticationValues();
    }
  }, [isLoggedIn, isLoggedInRedux]);

  return (
    <>
      <Helmet>
        <title>Settings - Gold Discovery</title>

        <meta name="title" content="Gold Discovery" />

        <meta name="description" content="Settings - GoldDiscovery.com" />
        <meta name="keywords" content="Settings - GoldDiscovery.com" />
        <meta property="og:title" content="Settings - GoldDiscovery.com" />
        <meta
          property="og:description"
          content="Settings - GoldDiscovery.com"
        />
        <meta
          property="og:image"
          content="https://golddiscovery.com/App-icon.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://golddiscovery.com/App-icon.jpg"
        />
      </Helmet>
      <Header />
      <div className="main-content">
        <div className="panel-blk">
          <div className="container">
            <div className="panbxs">
              <SideMenu />
              <>
                <div className="panel_top">
                  <div
                    className={`alert_set panel-b ${
                      isLoggedIn ? "panalt" : ""
                    }`}
                  >
                    <div className="al_ert">
                      <div className="usbx">
                        {isLoggedIn ? (
                          <li className="user">
                            <a onClick={handleProfileModal}>
                              <img
                                src={
                                  profile
                                    ? `${imageUrl}/${profile}`
                                    : "images/dummy_usr.svg"
                                }
                                alt=""
                              />
                              <strong onClick={handleProfilePage}>
                                Hi, {username}
                              </strong>
                              <span onClick={handleProfilePage}>
                                <img
                                  src={
                                    darkMode
                                      ? "images/edit profile.svg"
                                      : "images/edit profile blk.svg"
                                  }
                                />
                              </span>
                            </a>
                            {/* {profileModal && (
                              <ul className="logop">
                                <li>
                                  <a onClick={handleProfilePage}>My Profile</a>
                                </li>
                                <li>
                                  <a onClick={handleLogout}>Logout</a>
                                </li>
                              </ul>
                            )} */}
                          </li>
                        ) : (
                          <li className="user gusr">
                            <a onClick={handleLoginModalShow}>
                              <img src="images/dummy_usr.svg" alt="" />
                              <strong> Guest </strong>
                              <span>...</span>
                            </a>
                          </li>
                        )}
                      </div>
                      <div className="dril_alt">
                        <a href="#">
                          <img src="images/drill_dark.png" alt="" />
                          {/* <img
                            src={
                              fetchDarkMode
                                ? "images/drill_dark.svg"
                                : "images/drilalt.svg"
                            }
                            alt=""
                          /> */}
                        </a>
                        <div className="alt-set">
                          <h1>Alert Settings</h1>
                          <p>
                            Be notified everytime somthing important happens!
                            Receive push notification when drill results are
                            avilable and be the first one to get access to new
                            items
                          </p>
                        </div>
                      </div>
                      {/* <div className="user">
                      <a href="#">
                        <img src="images/usim.svg" alt="" />
                        <strong>Hi, Andrew</strong>
                      </a>
                    </div> */}

                      <ul
                        className="chose"
                        // onClick={() => handleDarkMode(!darkMode)}
                        // style={{ cursor: "pointer" }}
                      >
                        <li className={darkMode ? "" : "hid"}>
                          <img src="images/dark.svg" alt="" />
                          <strong>Dark Mode</strong>
                        </li>
                        <li>
                          <Switch
                            onChange={handleDarkMode}
                            checked={darkMode}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />
                        </li>
                      </ul>
                      <ul
                        className="chose pnofi"
                        // onClick={() => handlePushNotify(!pushNotify)}
                        // style={{ cursor: "pointer" }}
                      >
                        <li className={pushNotify ? "" : "hid"}>
                          <img src="images/notifi.svg" alt="" />
                          <strong> Notifications</strong>
                        </li>
                        <li>
                          <Switch
                            onChange={handlePushNotify}
                            checked={pushNotify}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />
                        </li>
                      </ul>
                      <ul className="serset">
                        <li className={pushNotify ? "" : "hid"}>
                          <strong>Drill Results</strong>
                        </li>
                        <li
                          className={allDrill ? "" : "hid"}
                          // onClick={() => handleAllDrill(!allDrill)}
                          // style={{ cursor: "pointer" }}
                        >
                          <strong>All Drill Results</strong>
                          <Switch
                            onChange={handleAllDrill}
                            checked={allDrill}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />
                        </li>
                        <li
                          className={drill50gm ? "" : "hid"}
                          // onClick={() => handleDrill50gm(!drill50gm)}
                          // style={{ cursor: "pointer" }}
                        >
                          <span>&gt;50 gram* meters (AuEq)</span>
                          <Switch
                            onChange={handleDrill50gm}
                            checked={drill50gm}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />
                        </li>
                        <li
                          className={drill100gm ? "" : "hid"}
                          // onClick={() => handleDrill100gm(!drill100gm)}
                          // style={{ cursor: "pointer" }}
                        >
                          <span>&gt;100 gram* meters (AuEq)</span>
                          <Switch
                            onChange={handleDrill100gm}
                            checked={drill100gm}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />
                        </li>
                      </ul>
                      <ul className="serset pro">
                        <li className={pushNotify ? "" : "hid"}>
                          <img
                            src={
                              darkMode
                                ? "images/pro white.svg"
                                : "images/pro.svg"
                            }
                            alt=""
                          />
                          <strong>Premium</strong>
                        </li>
                        <li className={potentialDis ? "" : "hid"}>
                          <strong>Alert for potential discoveries</strong>
                          <Switch
                            onChange={handlePotentialDis}
                            checked={potentialDis}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />
                        </li>
                        <li className={capAlert ? "" : "hid"}>
                          <strong>Alert for CapAlert</strong>
                          <Switch
                            onChange={handleCapAlert}
                            checked={capAlert}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />
                        </li>
                      </ul>
                      <ul className="serset">
                        <li className={pushNotify ? "" : "hid"}>
                          <strong>Trading halts</strong>
                        </li>
                        <li
                          className={allHalts ? "" : "hid"}
                          // onClick={() => handleAllHalts(!allHalts)}
                          // style={{ cursor: "pointer" }}
                        >
                          <span>All Halts</span>
                          <Switch
                            onChange={handleAllHalts}
                            checked={allHalts}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />
                        </li>
                        <li
                          className={exploHalts ? "" : "hid"}
                          // onClick={() => handleExploHalts(!exploHalts)}
                          // style={{ cursor: "pointer" }}
                        >
                          <span>Exploration Halts</span>
                          <Switch
                            onChange={handleExploHalts}
                            checked={exploHalts}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                          />
                        </li>
                      </ul>

                      <ul className="plys_ap">
                        <li>
                          <a
                            className="plyim"
                            href="https://play.google.com/store/apps/details?id=com.golddiscovery&pcampaignid=web_share"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src="images/plays.svg" alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            className="applim"
                            href="https://apps.apple.com/in/app/golddiscovery/id6449089048"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src="images/apple.svg" alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://golddiscovery.com/legacy/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </a>
                          &nbsp; and &nbsp;
                          <a
                            href="https://golddiscovery.com/legacy/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms and Conditions
                          </a>
                        </li>
                      </ul>
                      <a
                        className="legcybt"
                        href="https://golddiscovery.com/legacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Legacy Site
                      </a>

                      <div className="queries_in_setting">
                        <strong>Questions? E-mail us at</strong>
                        <a href="mailto:Luc@GoldDiscovery.com">
                          Luc@GoldDiscovery.com
                        </a>
                      </div>
                    </div>
                    <>
                      <Modal
                        show={showDiscoveriesModal}
                        onHide={() => setShowDiscoveriesModal(false)}
                        centered
                        className="setmod"
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <p>
                            Potential Discoveries are screened using AI (machine
                            learning) and language models. We cannot guarantee
                            that every discovery will be captured.
                          </p>
                          <p>&nbsp;</p>
                          <p>
                            The information in this app and/or in alerts is not
                            intended to provide investment advise, It shall not
                            be considered a recommandation, Solicitation, or
                            offer to buy or sell a security to any person in any
                            person in any jurisdiction
                          </p>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleDiscoveriesModalConfirm(true)}
                          >
                            ACTIVATE ALERT
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => handleDiscoveriesModalConfirm(false)}
                          >
                            BACK TO ALERT
                          </button>
                        </Modal.Footer>
                      </Modal>

                      <Modal
                        show={capAlertModal}
                        onHide={() => setCapAlertModal(false)}
                        centered
                        className="setmod"
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <p>
                            Cap Alerts are screened using AI (machine learning)
                            and language models. We cannot guarantee that every
                            discovery will be captured.
                          </p>
                          <p>&nbsp;</p>
                          <p>
                            The information in this app and/or in alerts is not
                            intended to provide investment advise, It shall not
                            be considered a recommandation, Solicitation, or
                            offer to buy or sell a security to any person in any
                            person in any jurisdiction
                          </p>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleCapAlertModalConfirm(true)}
                          >
                            ACTIVATE ALERT
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => handleCapAlertModalConfirm(false)}
                          >
                            BACK TO ALERT
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  </div>

                  {showSaveButton && (
                    <ul className="logsv">
                      <li onClick={handleLogout} disabled={isSubmitting}>
                        <a className="lgt">
                          {" "}
                          {isSubmitting ? "Logging Out..." : "Logout"}
                        </a>
                      </li>

                      <li>
                        <a onClick={handleSubmit}> Save Notifications</a>
                      </li>
                    </ul>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { useDispatch } from "react-redux";
import { setActivePage } from "../Redux/PageSlice";

const Signup = ({ handleBack, handleModalClose, handleSignupSuccess }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreePolicy, setAgreePolicy] = useState(true);
  const [agreeMailchimp, setAgreeMailChimp] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [userError, setUserError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");
  const [passMatError, setPassMatError] = useState("");
  const [policyError, setPolicyError] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [timeZoneError, setTimeZoneError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [placeholderText, setPlaceholderText] = useState("--");
  const [signupData, setSignupData] = useState([]);

  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const timezoneOptions = Object.values(timeZone).map((tz) => ({
    value: `${tz.id}`,
    label: `${tz.country} | ${tz.city}`,
  }));
  const dispatch = useDispatch();

  const isFocus = document.hasFocus();

  useEffect(() => {
    if (isFocus) {
      dispatch(setActivePage(false));
      // Add your condition or action here
    }
  }, [isFocus]);

  useEffect(() => {
    // Load captcha when the component mounts
    loadCaptchaEnginge(6, "#16264c", "white");
  }, []);

  // fetch timezones list
  useEffect(() => {
    const fetchTimeZone = async () => {
      try {
        const formData = new FormData();
        formData.append("settings", 1);

        const response = await BaseApi.post(url.appSettings, formData);
        // console.log("timeZone Api:", response);
        setTimeZone(response.data.timezones);
        setSignupData(response.data.usp || []);
      } catch (error) {
        // console.error("Error fetching :", error);
      } finally {
      }
    };
    fetchTimeZone();
  }, []);

  useEffect(() => {
    // Validate username
    const spaceCheck = /\s/;

    if (formSubmitted) {
      if (!username.trim()) {
        setUserError("Please specify your name");
      } else if (spaceCheck.test(username)) {
        setUserError("Usernames cannot contain spaces");
      } else {
        setUserError("");
      }
    }
  }, [username, formSubmitted]);

  useEffect(() => {
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (formSubmitted && !email.trim()) {
      setEmailError("We need your email address to contact you");
    } else if (formSubmitted && !emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  }, [email, formSubmitted]);

  useEffect(() => {
    // Validate password
    if (formSubmitted && !password.trim()) {
      setPassError("Please specify your password");
    } else if (formSubmitted && password.length < 6) {
      setPassError("Password should be minimum 6 characters");
    } else {
      setPassError("");
    }
  }, [password, formSubmitted]);

  useEffect(() => {
    // Validate confirmPassword
    if (
      formSubmitted &&
      password !== confirmPassword &&
      confirmPassword.trim()
    ) {
      setPassMatError("Please enter the same value again");
    } else {
      setPassMatError("");
    }
  }, [password, confirmPassword, formSubmitted]);

  useEffect(() => {
    // Validate agreePolicy
    if (formSubmitted && !agreePolicy) {
      setPolicyError("Please select agree policy");
    } else {
      setPolicyError("");
    }
  }, [agreePolicy, formSubmitted]);

  const validateCaptchaCode = (value) => {
    if (!value.trim() || !validateCaptcha(value)) {
      setCaptchaError("Invalid captcha code");
      return false;
    } else {
      setCaptchaError("");
      return true;
    }
  };

  useEffect(() => {
    // Validate captcha
    if (formSubmitted && !captchaValue.trim()) {
      setCaptchaError("This field is required");
    } else {
      setCaptchaError("");
    }
  }, [captchaValue, formSubmitted]);

  useEffect(() => {
    // Validate timezone
    if (formSubmitted && !selectedTimezone) {
      setTimeZoneError("Please specify your timezone");
    } else {
      setTimeZoneError("");
    }
  }, [selectedTimezone, formSubmitted]);

  const handleCaptchaChange = (value) => {
    // Handle captcha value change
    setCaptchaValue(value);
  };

  const handleRegenerateCaptcha = () => {
    loadCaptchaEnginge(6, "#16264c", "white");
  };

  const handleAgreePolicyChange = () => {
    setAgreePolicy(!agreePolicy); // Toggle the value
  };

  const handleAgreeMailchimpChange = () => {
    setAgreeMailChimp(!agreeMailchimp); // Toggle the value
  };

  const handleSubmit = () => {
    // Disable the submit button to prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    // Set formSubmitted to true to trigger error messages
    setFormSubmitted(true);

    // Validate captcha
    if (!validateCaptchaCode(captchaValue)) {
      // Any of the validations failed, do not proceed with the API call
      setCaptchaError("Invalid Captcha Code");
      // console.log("Invalid Captcha");
      return;
    }
    // Check if all validations passed before making the API call
    if (
      !username ||
      !email ||
      !password ||
      password.length < 6 ||
      password !== confirmPassword ||
      !agreePolicy ||
      !captchaValue.trim() ||
      !validateCaptcha(captchaValue) ||
      !selectedTimezone
    ) {
      // Log the validation errors to the console
      // console.error("Validation Errors:", {
      //   username,
      //   email,
      //   password,
      //   confirmPassword,
      //   agreePolicy,
      //   captchaValue,
      //   selectedTimezone,
      // });

      // Any of the validations failed, do not proceed with the API call
      return;
    }

    // Convert the selectedTimezone object to the desired format
    const formattedTimezone = {
      id: selectedTimezone.value, // Assuming 'value' represents the id
      timezone: selectedTimezone.label,
      country: selectedTimezone.label.split(" | ")[0], // Extracting country from label
      city: selectedTimezone.label.split(" | ")[1], // Extracting city from label
    };

    // Disable the submit button
    setIsSubmitting(true);

    const formdata = new FormData();
    formdata.append("username", username);
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("agree_policy", agreePolicy ? 1 : 0);
    formdata.append("agree_mailchimp", agreeMailchimp ? 1 : 0);
    formdata.append("timezone", JSON.stringify(formattedTimezone));

    // Log the formdata for debugging purposes
    // console.log("Formdata:", formattedTimezone);

    BaseApi.post(url.register, formdata)
      .then((response) => {
        if (response && response.data) {
          // Check the API response status
          switch (response.data.status) {
            case "success":
              // Successful signup (toast success)
              toast.success(response.data.toast);
              // console.log("Signup Success API", response);

              handleSignupSuccess();
              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
            // console.warn("Unexpected response status:", response.data.status);
          }
        } else {
          // console.error("Unexpected response format:", response);
        }

        setIsSubmitting(false);
      })
      .catch((error) => {
        // API request failed (toast error)
        setIsSubmitting(false);

        toast.error("Signup failed. Please try again later.");

        // console.log("Error:", error);
      });
  };

  return (
    <>
      <div className="l-frm signup">
        <div className="l-frc">
          <h1>Register Your Account</h1>
          <div className="lf-cont">
            <div className="lf">
              <input
                type="text"
                placeholder="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <span className="error">{userError}</span>
            </div>
            <div className="lf">
              <input
                type="text"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <span className="error">{emailError}</span>
            </div>
            <div className="lf lfs">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="shhi"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <div
                className="eye"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <img src="images/show.svg" alt="show" />
                ) : (
                  <img src="images/hide.svg" alt="hide" />
                )}
              </div>
              <span className="error">{passError}</span>
            </div>

            <div className="lf">
              <input
                type="password"
                placeholder=" Confirm Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              <span className="error">{passMatError}</span>
            </div>
            <div className="sel">
              <Select
                options={timezoneOptions}
                value={selectedTimezone}
                onChange={(selectedOption) =>
                  setSelectedTimezone(selectedOption)
                }
                onFocus={() =>
                  setPlaceholderText("Search for nearest city or country")
                }
                onBlur={() => setPlaceholderText("--")}
                isSearchable
                placeholder={placeholderText}
              />
              <span className="error">{timeZoneError}</span>
            </div>
          </div>
          <ul className="chk-for sig-ck">
            <li>
              <span className="error">{policyError}</span>

              <label>
                <input
                  type="checkbox"
                  checked={agreePolicy}
                  onChange={handleAgreePolicyChange}
                />
                <p>
                  I accept the
                  <a
                    href="https://golddiscovery.com/legacy/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </a>
                  and
                  <a
                    href="https://golddiscovery.com/legacy/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                </p>
              </label>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  checked={agreeMailchimp}
                  onChange={handleAgreeMailchimpChange}
                />
                <p>Subscribe to our newsletter?</p>
              </label>
            </li>
          </ul>

          <div className="cap-cha">
            <LoadCanvasTemplate onChange={handleCaptchaChange} />
            <input
              type="text"
              placeholder="Please enter captcha code"
              value={captchaValue}
              onChange={(e) => setCaptchaValue(e.target.value)}
            />
            <p>
              Cannot read the image?
              <a onClick={handleRegenerateCaptcha}> Click here </a>
              to regenerate a new CAPTCHA image.
            </p>
            <span className="error">{captchaError}</span>
          </div>

          <button
            className="logbt"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Signing in..." : "Confirm your free signup "}
          </button>

          <button className="close" onClick={handleModalClose}>
            <span>x</span>
          </button>
          <button className="mbk bck" onClick={handleBack}>
            <img src="images/arw.svg" alt="" />
          </button>
        </div>
        {signupData && signupData.length > 0 && (
          <div className="sign">
            <ul>
              {signupData.map(
                (item) =>
                  // Check if the status is 1 before rendering the <li>
                  item.status === "1" && <li key={item.id}>{item.content}</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Signup;

import React, { useState, useEffect, useRef } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { triggerAlertModal, setAlertsDataEmpty } from "../Redux/alertSlice";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import NewsShimmer from "../Components/NewsShimmer";
import Spinner from "react-bootstrap/Spinner";
// import InfiniteScroll from "react-infinite-scroller";
import { setAlertCount } from "../Redux/alertSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { setActivePage } from "../Redux/PageSlice";

const Alerts = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isAlertModalVisible = useSelector(
    (state) => state.alert.isAlertModalVisible
  );

  const alertCount = useSelector((state) => state.alert.alertCount);

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const [comloading, setComLoading] = useState(false);
  const [alertsData, setAlertsData] = useState([]);
  const [startValue, setStartValue] = useState(0);
  const [limitValue, setLimitValue] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);

  const [hasMore, setHasMore] = useState(true);
  const [apiCallCounter, setApiCallCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const userIdLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("userId") || null,
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const userIdRedux = useSelector((state) => state.auth.userId);
  const userId = userIdLocal || userIdRedux;

  const isFocus = document.hasFocus();

  useEffect(() => {
    if (isFocus) {
      dispatch(setActivePage(false));
      // Add your condition or action here
    }
  }, [isFocus]);

  useEffect(() => {
    document.title = "Alerts - GoldDiscovery.com";

    const metaTitle = document.querySelector('meta[name="title"]');
    if (metaTitle) {
      metaTitle.content = "Alerts - GoldDiscovery.com";
    }

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = "Alerts - GoldDiscovery.com";
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = "Alerts - GoldDiscovery.com";
    }

    return () => {
      document.title = "GoldDiscovery.com";

      if (metaTitle) {
        metaTitle.content = "GoldDiscovery.com";
      }

      if (metaDescription) {
        metaDescription.content = "";
      }

      if (metaKeywords) {
        metaKeywords.content = "";
      }
    };
  }, []);

  useEffect(() => {
    const fetchAlertsData = async () => {
      try {
        if (!count || count === null) {
          setComLoading(true);
        } else {
          setLoading(true);
        }
        const formData = new FormData();
        formData.append("start", startValue);
        formData.append("userId", userId);

        // console.log("userid", userId);

        const response = await BaseApi.post(url.stored_notifications, formData);
        // console.log("Alerts Data Api:", response);

        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          setAlertsData(response.data.data);
          setStartValue(response.data.start);
          setLimitValue(response.data.limit);
          setTotalCount(response.data.total);
          setCount(response.data.count);
          dispatch(setAlertsDataEmpty(false));
          setInitialDataFetched(true); // Set the flag to true after initial data fetch
        } else {
          dispatch(setAlertsDataEmpty(true));
        }
      } catch (error) {
        // console.error("Error fetching Alerts data:", error);
      } finally {
        setComLoading(false);
      }
    };

    fetchAlertsData();
  }, []);

  const GMcolor = (gandm) => {
    const colors = {
      "> 10000": { bgcolor: "#375623", fcolor: "#fff" },
      "1000 - 10000": { bgcolor: "#548235", fcolor: "#fff" },
      "750 - 1000": { bgcolor: "#61953D", fcolor: "#fff" },
      "500 - 750": { bgcolor: "#6DA945", fcolor: "#fff" },
      "250 - 500": { bgcolor: "#81BB59", fcolor: "#fff" },
      "150 - 250": { bgcolor: "#99C979", fcolor: "#fff" },
      "100 - 150": { bgcolor: "#AAD18F", fcolor: "#fff" },
      "75 - 100": { bgcolor: "#C7E1B5", fcolor: "#000" },
      "60 - 75": { bgcolor: "#DDECD4", fcolor: "#000" },
      "50 - 60": { bgcolor: "#EDF5E7", fcolor: "#000" },
      "40 - 50": { bgcolor: "#F4F9F1", fcolor: "#000" },
      "30 - 40": { bgcolor: "#FBF9F3", fcolor: "#000" },
      "20 - 30": { bgcolor: "#F9F6EB", fcolor: "#000" },
      "10 - 20": { bgcolor: "#F9F8DF", fcolor: "#000" },
      "0 - 10": { bgcolor: "#F6F5D2", fcolor: "#000" },
      default: { bgcolor: "#F6F5D2", fcolor: "#000" },
    };

    let range;

    if (gandm > 10000) {
      range = "> 10000";
    } else if (gandm > 1000) {
      range = "1000 - 10000";
    } else if (gandm > 750) {
      range = "750 - 1000";
    } else if (gandm > 500) {
      range = "500 - 750";
    } else if (gandm > 250) {
      range = "250 - 500";
    } else if (gandm > 150) {
      range = "150 - 250";
    } else if (gandm > 100) {
      range = "100 - 150";
    } else if (gandm > 75) {
      range = "75 - 100";
    } else if (gandm > 60) {
      range = "60 - 75";
    } else if (gandm > 50) {
      range = "50 - 60";
    } else if (gandm > 40) {
      range = "40 - 50";
    } else if (gandm > 30) {
      range = "30 - 40";
    } else if (gandm > 20) {
      range = "20 - 30";
    } else if (gandm > 10) {
      range = "10 - 20";
    } else if (gandm > 0) {
      range = "0 - 10";
    } else {
      range = "default";
    }

    return colors[range];
  };

  const handleSingleDelete = (newsId) => {
    // console.log("Swipe delete called with newsId:", newsId);

    const formdata = new FormData();
    formdata.append("userId", userId);
    formdata.append("newsId", newsId);
    formdata.append("deleteMode", "single");

    BaseApi.post(url.delete_stored_notifications, formdata)
      .then((response) => {
        if (response && response.data) {
          // console.log(response);

          // Check the API response status
          switch (response.data.status) {
            case "success":
              // Successful Email Sent (toast success)
              toast.success(response.data.toast);
              // console.log("Alert Swp Del API", response);
              const pushCount = response.data.alerts_count;
              dispatch(setAlertCount(pushCount));
              localStorage.setItem("alertCount", pushCount);

              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            case "danger":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
              console.warn("Unexpected response status:", response.data.status);
            // console.log("Unexpected response status:", response.data.status);
          }
        } else {
          console.error("Unexpected response format:", response);
        }
      })
      .catch((error) => {
        // API request failed (toast error)
        toast.error("Request failed. Please try again later.");
        // console.log("Error:", error);
      });
  };

  const handleAllDelete = () => {
    const formdata = new FormData();
    formdata.append("userId", userId);
    formdata.append("deleteMode", "multiple");

    BaseApi.post(url.delete_stored_notifications, formdata)
      .then((response) => {
        if (response && response.data) {
          // console.log(response);
          // Check the API response status

          switch (response.data.status) {
            case "success":
              // Successful Email Sent (toast success)
              toast.success(response.data.toast);
              // console.log("Alert All Del API", response);
              // Update the state immediately after successful deletion

              setAlertsData([]);

              dispatch(setAlertCount(0));
              localStorage.setItem("alertCount", 0);

              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            case "danger":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
            // console.warn("Unexpected response status:", response.data.status);
            // console.log("Unexpected response status:", response.data.status);
          }
        } else {
          // console.error("Unexpected response format:", response);
        }
      })
      .catch((error) => {
        // API request failed (toast error)
        toast.error("Request failed. Please try again later.");
        // console.log("Error:", error);
      });
  };

  const navigateToNewsDetail = (newsId, slugurl) => {
    handleAlertReaded(newsId);
    navigate(`/news/${slugurl}`);
    window.location.reload();
  };

  const handleAlertModalConfirm = (confirmed) => {
    if (confirmed) {
      // If confirmed, enable discoveries
      // console.log("User Clicked Yes");
      handleAllDelete();
    } else {
      // If canceled, disable discoveries
      // console.log("User Clicked No");
    }

    dispatch(triggerAlertModal());
  };

  const trailingActions = (newsId) => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => handleSingleDelete(newsId)}
      >
        <div className="partial-delete">Full Swipe To Delete</div>
      </SwipeAction>
    </TrailingActions>
  );

  const handleAlertReaded = (newsId) => {
    // console.log("Alert readed called with newsId:", newsId);

    const formdata = new FormData();
    formdata.append("userId", userId);
    formdata.append("newsId", newsId);
    formdata.append("deleteMode", "read");

    BaseApi.post(url.delete_stored_notifications, formdata)
      .then((response) => {
        // console.log(response);
        dispatch(setAlertCount(alertCount - 1));
      })
      .catch((error) => {
        toast.error("Request failed. Please try again later.");
        // console.log("Error:", error);
      });
  };

  const fetchAlertNext = async () => {
    // Check if initial data fetch has been completed
    if (!initialDataFetched) {
      return;
    }
    // console.log("FetchAiNext Triggered");
    try {
      if (!count || count === null) {
        setComLoading(true);
      } else {
        setLoading(true);
      }
      const nextValue = Number(startValue) + Number(limitValue);
      const formData = new FormData();
      formData.append("start", nextValue);
      formData.append("userId", userId);

      const response = await BaseApi.post(url.stored_notifications, formData);
      // console.log("Alerts Next Api:", response);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        // console.log(
        //   "start is",
        //   nextValue,
        //   "Response Length",
        //   response.data.data.length,
        //   "Api call Count",
        //   apiCallCounter,
        //   "api Count",
        //   totalCount
        // );
        setAlertsData((prevData) => [...prevData, ...response.data.data]);
        setStartValue(String(nextValue));

        // Check if nextValue exceeds the total count or apiCallCount exceeds response count
        if (apiCallCounter >= response.data.count) {
          setHasMore(false);
        } else {
          // Increment the apiCallCount if conditions are not met
          setApiCallCounter((prevCount) => prevCount + 1);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      // console.error("Error fetching Alerts news:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Alerts - Gold Discovery</title>
        <meta name="title" content="Gold Discovery" />
        <meta name="description" content="Alerts - GoldDiscovery.com" />
        <meta name="keywords" content="Alerts - GoldDiscovery.com" />
        <meta property="og:title" content="Alerts - GoldDiscovery.com" />
        <meta property="og:description" content="Alerts - GoldDiscovery.com" />
        <meta
          property="og:image"
          content="https://golddiscovery.com/App-icon.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://golddiscovery.com/App-icon.jpg"
        />
      </Helmet>
      <Header />
      <div className="main-content">
        <div className="panel-blk">
          <div className="container">
            <div className="panbxs">
              <SideMenu />
              {comloading ? (
                <NewsShimmer />
              ) : (
                <>
                  <div className="panel_top">
                    <div className="panel-b alrts" id="scrollable">
                      <InfiniteScroll
                        hasMore={hasMore && limitValue > 0}
                        next={fetchAlertNext}
                        dataLength={alertsData.length}
                        scrollableTarget="scrollable"
                      >
                        <SwipeableList>
                          {alertsData.length > 0 ? (
                            alertsData.map((alert, index) => (
                              <SwipeableListItem
                                key={index}
                                trailingActions={trailingActions(
                                  alert.content.data.news_id
                                )}
                                // maxSwipe={0.25}
                              >
                                <div
                                  className="alts"
                                  key={index}
                                  title="Swipe Left to Delete"
                                >
                                  <div className="alrt-cont">
                                    <a
                                      className={`bel ${
                                        alert.content.data.readed === 0
                                          ? ""
                                          : "active"
                                      }`}
                                    >
                                      <img src="images/mani.svg" alt="" />
                                    </a>
                                    <div className="othc">
                                      <p
                                        onClick={() =>
                                          navigateToNewsDetail(
                                            alert.content.data.news_id,
                                            alert.content.data.slug_url
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <strong>
                                          {alert.content.notification.title}
                                          &nbsp;
                                        </strong>
                                        {alert.content.notification.body}
                                      </p>
                                      <div className="dar-lab">
                                        <span className="dat">
                                          {alert.content.data.news_dt} (ET)
                                        </span>

                                        {alert.content.data.news_sy.trim()
                                          .length > 0 && (
                                          <span
                                            className={`label ${
                                              alert.content.data.news_sy.endsWith(
                                                ":AU"
                                              )
                                                ? "labelau"
                                                : ""
                                            }`}
                                          >
                                            {alert.content.data.news_sy}
                                          </span>
                                        )}

                                        <ul className="alt-lab">
                                          {alert.content.data.discovery &&
                                            alert.content.data.discovery.map(
                                              (
                                                discoveryItem,
                                                discoveryIndex
                                              ) => (
                                                <li key={discoveryIndex}>
                                                  <span
                                                    className="fc"
                                                    style={{
                                                      backgroundColor:
                                                        discoveryItem === "TH"
                                                          ? "#FFCF37"
                                                          : discoveryItem ===
                                                            "CA"
                                                          ? "#AF5556"
                                                          : "",
                                                      color:
                                                        discoveryItem ===
                                                          "TH" ||
                                                        discoveryItem === "CA"
                                                          ? "#ffffff"
                                                          : "",
                                                    }}
                                                  >
                                                    {discoveryItem}
                                                  </span>
                                                </li>
                                              )
                                            )}
                                        </ul>

                                        {alert.content.data.news_gm.trim()
                                          .length > 0 && (
                                          <span
                                            className="sc"
                                            style={{
                                              backgroundColor: GMcolor(
                                                alert.content.data.news_gm
                                              ).bgcolor,
                                              color: GMcolor(
                                                alert.content.data.news_gm
                                              ).fcolor,
                                            }}
                                          >
                                            {Number(
                                              alert.content.data.news_gm
                                            ).toFixed(0)}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <button className="alrtim">
                                      <img src="images/dust.svg" alt="" />
                                    </button>
                                  </div>
                                </div>
                              </SwipeableListItem>
                            ))
                          ) : (
                            <div className="alertempty">
                              <p>
                                You're all set with notifications? Voila!
                                <br />
                                Alerts will be right here on this page
                              </p>
                            </div>
                          )}
                        </SwipeableList>
                        {loading && (
                          <div className="load_spinner">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        )}
                      </InfiniteScroll>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {isAlertModalVisible && (
        <>
          <Modal
            show={isAlertModalVisible}
            onHide={() => dispatch(triggerAlertModal())}
            centered
            className="setmod al"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <p>Are you sure want to delete all received alerts?</p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary"
                onClick={() => handleAlertModalConfirm(true)}
              >
                YES
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => handleAlertModalConfirm(false)}
              >
                NO
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      <Footer />
    </>
  );
};

export default Alerts;

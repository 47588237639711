import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../API/BaseApi";
import { useParams, useLocation } from "react-router-dom";
import { setActivePage } from "../Redux/PageSlice";

const NotFound = () => {
  const SECRET_KEY = process.env.REACT_APP_GOLD;
  const fetchDarkMode = useSelector((state) => state.darkMode);
  const location = useLocation();
  const isFocus = document.hasFocus();
  const dispatch = useDispatch();

  useEffect(() => {
    // Check if the user is on the "Not Found" page
    dispatch(setActivePage(true));
    // console.log("User is focused on the Not Found page");
    // Add your condition or action here
  }, []);

  // useEffect(() => {
  //   if (!isFocus) {
  //     dispatch(setActivePage(false));
  //     console.log("User is un focused on the Not Found page");
  //     // Add your condition or action here
  //   }
  // }, [!isFocus]);

  useEffect(() => {
    // Update meta tags when the component mounts
    document.title = "404 - Gold Discovery | GoldDiscovery";

    const metaTitle = document.querySelector('meta[name="title"]');
    if (metaTitle) {
      metaTitle.content = "404 - Gold Discovery | GoldDiscovery";
    }

    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaDescription) {
      metaDescription.content = "404 - Gold Discovery | GoldDiscovery";
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = "404 - Gold Discovery | GoldDiscovery";
    }

    // // Get darkMode value from local storage
    // const encryptedDarkMode = localStorage.getItem("darkMode");
    // const fetchDarkMode = encryptedDarkMode
    //   ? CryptoJS.AES.decrypt(encryptedDarkMode, SECRET_KEY).toString(
    //       CryptoJS.enc.Utf8
    //     ) === "true"
    //   : false;

    // Set the 'darkmode' class on the body
    const bodyElement = document.querySelector("body");
    if (fetchDarkMode) {
      bodyElement.classList.add("darkmode");
    } else {
      bodyElement.classList.remove("darkmode");
    }

    // Clean up when the component unmounts
    return () => {
      document.title = "GoldDiscovery.com";

      if (metaTitle) {
        metaTitle.content = "GoldDiscovery.com";
      }

      if (metaDescription) {
        metaDescription.content = "";
      }

      if (metaKeywords) {
        metaKeywords.content = "";
      }
    };
  }, []);

  // // Decrypt local storage data
  // const encryptedDarkMode = localStorage.getItem("darkMode") || "";
  // const decryptedDarkMode = CryptoJS.AES.decrypt(
  //   encryptedDarkMode,
  //   SECRET_KEY
  // ).toString(CryptoJS.enc.Utf8);
  // const isDarkModeEnabled = decryptedDarkMode === "true";

  // const logoSrc = isDarkModeEnabled
  //   ? `${process.env.PUBLIC_URL}/images/hlogh.svg`
  //   : `${process.env.PUBLIC_URL}/images/hlog.svg`;

  const logoSrc = fetchDarkMode
    ? `${process.env.PUBLIC_URL}/images/hlogh.svg`
    : `${process.env.PUBLIC_URL}/images/hlog.svg`;

  return (
    <>
      <Helmet>
        <title>404 - Gold Discovery | GoldDiscovery</title>

        <meta name="title" content="404 - Gold Discovery | GoldDiscovery" />

        <meta
          name="description"
          content="404 - Gold Discovery | GoldDiscovery"
        />
        <meta name="keywords" content="404 - Gold Discovery | GoldDiscovery" />
        <meta name="og:title" content="404 - Gold Discovery | GoldDiscovery" />
        <meta
          name="og:description"
          content="404 - Gold Discovery | GoldDiscovery"
        />
        <meta
          name="og:image"
          content="https://golddiscovery.com/App-icon.jpg"
        />
      </Helmet>
      <header>
        <div className="header_outer">
          <div className="container">
            <div className="header_inner">
              <div className="head_logo">
                <a href={url.shareurl}>
                  <img src={logoSrc} alt=""></img>
                </a>
              </div>

              <div className="head_ryt_pops">
                <div className="hd_sign">
                  <a className="sign-in">
                    <button type="button" className="register bck">
                      Sign In
                    </button>
                  </a>
                </div>
                <div className="hd_start">
                  <a className="get-strt ac">Get Started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="main_content">
        <div className="page_404">
          <div className="container">
            <div className="err_img">
              <h1>Woops...!</h1>
              <p>We seem to have hit a snag. Please try again later....</p>
              <a href={url.shareurl} className="back">
                Return to homepage
              </a>
            </div>
            <div className="biim">
              <img src="images/notfound.png" alt=""></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;

import { configureStore } from "@reduxjs/toolkit";
import symbolCodeReducer from "./symbolCodeSlice";
import activeSectionReducer from "./activeSectionSlice";
import authReducer from "./authSlice";
import modalReducer from "./modalSlice";
import darkModeReducer from "./darkModeSlice";
import alertReducer from "./alertSlice";
import PageReducer from './PageSlice'


// import dateRangeReducer from "./dateRangeSlice";

const store = configureStore({
  reducer: {
    symbolCode: symbolCodeReducer,
    activeSection: activeSectionReducer,
    auth: authReducer,
    modal: modalReducer,
    darkMode: darkModeReducer,
    alert: alertReducer,
    activePage:PageReducer
    // dateRange: dateRangeReducer,
  },
});

export default store;

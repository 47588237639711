import React, { useState, useEffect } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import Header from "../Header/Header";
import News from "../Header/News";
import SideMenu from "./SideMenu";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { Helmet } from "react-helmet";
import NewsShimmer from "../Components/NewsShimmer";
import { useDispatch } from "react-redux";
import { setAlertCount } from "../Redux/alertSlice";
import { setActivePage } from "../Redux/PageSlice";

// import axios from "axios";

const Home = () => {
  // const activeSection = useSelector((state) => state.activeSection);

  const location = useLocation();
  const dispatch = useDispatch();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  // Decrypt local storage data
  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const userIdLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("userId") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);
  const userIdRedux = useSelector((state) => state.auth.userId);

  const isLoggedIn = isLoggedInLocal || isLoggedInRedux;
  const userId = userIdLocal || userIdRedux;

  const [comloading, setComLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [startValue, setStartValue] = useState(0);
  const [limitValue, setLimitValue] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [newsData, setNewsData] = useState([]);

  const [filterList, setFilterList] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const [applied, setApplied] = useState(false);
  const [newsPoll, setNewsPoll] = useState([]);
  const [pollStart, setPollStart] = useState(0);
  const [pollLength, setPollLength] = useState(0);
  const [pollIndicator, setPollIndicator] = useState(0);
  const [vistLength, setVistLength] = useState(0);

  // console.log("ActiveSection is :", activeSection);
  const isFocus = document.hasFocus();

  useEffect(() => {
    // if (isFocus) {
    dispatch(setActivePage(false));
    // Add your condition or action here
    // }
  }, [isFocus]);

  const fetchDarkMode = useSelector((state) => state.darkMode);

  useEffect(() => {
    const bodyElement = document.querySelector("body");

    if (fetchDarkMode) {
      bodyElement.classList.add("darkmode");
    } else {
      bodyElement.classList.remove("darkmode");
    }
  }, [fetchDarkMode]);

  useEffect(() => {
    // Update meta tags when the component mounts
    document.title = "News - GoldDiscovery.com";

    const metaTitle = document.querySelector('meta[name="title"]');
    if (metaTitle) {
      metaTitle.content = "News - GoldDiscovery.com";
    }

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = "News - GoldDiscovery.com";
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = "News - GoldDiscovery.com";
    }

    // Clean up when the component unmounts
    return () => {
      document.title = "GoldDiscovery.com";

      if (metaTitle) {
        metaTitle.content = "GoldDiscovery.com";
      }

      if (metaDescription) {
        metaDescription.content = "";
      }

      if (metaKeywords) {
        metaKeywords.content = "";
      }
    };
  }, []);

  useEffect(() => {
    const fetchAlertCount = async () => {
      try {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("settings", 1);

        const response = await BaseApi.post(url.appSettings, formData);
        // console.log("Notification InitialValue Api:", response);

        if (response && response.data.pushSettings.length > 0) {
          const pushSettings = response.data.pushSettings[0];
          const pushCount = pushSettings.alerts_count;
          dispatch(setAlertCount(pushCount));
          localStorage.setItem("alertCount", pushCount);
        }
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      } finally {
      }
    };
    fetchAlertCount();
  }, [isLoggedIn]);

  // Fetch News Component
  useEffect(() => {
    const fetchMiningNews = async () => {
      try {
        if (!totalCount || totalCount === null) {
          setComLoading(true);
        } else {
          setLoading(false);
        }
        const formData = new FormData();
        formData.append("start", startValue);
        formData.append("extra", pollLength);

        if (selectedLabels.length > 0) {
          const labelsString = selectedLabels.join(",");
          formData.append("labels", labelsString);
        }

        const response = await BaseApi.post(url.miningNews, formData);
        // console.log("News Api:", response);

        setNewsData(response.data.data);
        setLimitValue(response.data.limit);
        setTotalCount(response.data.count);
        setStartValue(response.data.start);

        localStorage.setItem("maxId", response.data.max);

        // setStartValue(response.data.start);
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      } finally {
        setComLoading(false);
        setApplied(false);
        setLoading(false);
      }
    };

    fetchMiningNews();
  }, [applied]);

  const INTERVAL_TIME = 30000; // 30 seconds

  useEffect(() => {
    const fetchNewsPoll = async () => {
      try {
        if (newsData.length > 0) {
          const max = localStorage.getItem("maxId");

          // Check if max is not null, undefined, or empty
          if (max) {
            const formData = new FormData();
            formData.append("recId", Number(max));

            const response = await BaseApi.post(url.latest_news, formData);
            // console.log("Polling News Api:", response);

            if (response.data && response.data.data.length > 0) {
              setNewsPoll(response.data.data);
              setPollStart(response.data.data.length);
              setPollLength(response.data.data.length);
              setPollIndicator(response.data.data.length);
              setVistLength(response.data.data.length);

              localStorage.setItem("maxId", response.data.max);
            }

            // console.log("MaxId", response.data.max);
          }
        }
      } catch (error) {
        // console.error("Error fetching latest news:", error);
      }
    };

    const intervalId = setInterval(() => {
      fetchNewsPoll();
    }, INTERVAL_TIME);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [newsData]);

  const toggleLabelSelection = (labelId) => {
    setSelectedLabels((prevLabels) => {
      if (prevLabels.includes(labelId)) {
        // If label is already selected, remove it
        return prevLabels.filter((id) => id !== labelId);
      } else {
        // If label is not selected, add it
        return [...prevLabels, labelId];
      }
    });
  };

  // fetch filter labels
  useEffect(() => {
    const fetchFilterList = async () => {
      try {
        setComLoading(true);

        const formData = new FormData();
        formData.append("settings", 1);

        const response = await BaseApi.post(url.appSettings, formData);
        // console.log("Filter Api:", response);
        setFilterList(response.data.labels);
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      } finally {
        setComLoading(false);
      }
    };
    fetchFilterList();
  }, []);

  const handleApplyFilter = () => {
    // Check if any labels are selected
    if (selectedLabels.length > 0) {
      // If labels are selected, trigger the data fetching with the updated formData
      setStartValue(0);
      setApplied(true);
      setNewsData([]);
      setLimitValue(0);
      setTotalCount(0);

      setNewsPoll([]);
      setPollStart(0);
      setPollLength(0);
      setPollIndicator(0);
      setVistLength(0);

      setComLoading(true);
    } else {
      // If no labels are selected, you can show a message or handle it in a way that suits your application
      setStartValue(0);
      setApplied(true);
      setComLoading(true);
    }
  };

  const handleClearSelection = () => {
    // Clear the selected labels
    setSelectedLabels([]);
  };

  return (
    <>
      <Helmet>
        <title>News - GoldDiscovery.com</title>

        <meta name="title" content="Gold Discovery" />
        <meta name="description" content="News - GoldDiscovery.com" />
        <meta name="keywords" content="News - GoldDiscovery.com" />
        <meta property="og:title" content="News - GoldDiscovery.com" />
        <meta property="og:description" content="News - GoldDiscovery.com" />
        <meta
          property="og:image"
          content="https://golddiscovery.com/App-icon.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://golddiscovery.com/App-icon.jpg"
        />
      </Helmet>
      <>
        <Header />
        <div className="main-content">
          <div className="panel-blk">
            <div className="container">
              <div className="panbxs">
                <SideMenu />
                {comloading ? (
                  <NewsShimmer />
                ) : (
                  <>
                    <News
                      newsData={newsData}
                      setNewsData={setNewsData}
                      startValue={startValue}
                      limitValue={limitValue}
                      setStartValue={setStartValue}
                      loading={loading}
                      setLoading={setLoading}
                      setComLoading={setComLoading}
                      totalCount={totalCount}
                      setTotalCount={setTotalCount}
                      selectedLabels={selectedLabels}
                      pollLength={pollLength}
                      pollStart={pollStart}
                      setPollLength={setPollLength}
                      newsPoll={newsPoll}
                      pollIndicator={pollIndicator}
                      setPollIndicator={setPollIndicator}
                      // ScrollIndicator={ScrollIndicator}
                      vistLength={vistLength}
                      setVistLength={setVistLength}
                    ></News>
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="cofilter"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="mfilter">
                  <div className="mhd">
                    <strong>Please select a news topic</strong>
                    <a onClick={handleClearSelection}>clear</a>
                  </div>

                  <div className="nopp">
                    <ul>
                      {filterList.map((label) => (
                        <li key={label.id}>
                          {isLoggedIn ||
                          (label.label !== "Potential discovery" &&
                            label.label !== "CapAlert") ? (
                            <label>
                              <input
                                type="checkbox"
                                className="chk"
                                onChange={() => toggleLabelSelection(label.id)}
                                checked={selectedLabels.includes(label.id)}
                              />
                              <p>{label.label}</p>
                            </label>
                          ) : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <a
                    className="aply"
                    // data-bs-dismiss={selectedLabels.length > 0 ? "modal" : ""}
                    onClick={() => handleApplyFilter()}
                    data-bs-dismiss="modal"
                  >
                    Apply
                  </a>
                  <button className="close" data-bs-dismiss="modal">
                    <span>x</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    </>
  );
};

export default Home;

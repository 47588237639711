import React, { useState, useEffect, useRef } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import Header from "./Header";
import SideMenu from "../Home/SideMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { setSymbolCode } from "../Redux/symbolCodeSlice";
import { useDispatch } from "react-redux";
import Footer from "../Home/Footer";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import NewsShimmer from "../Components/NewsShimmer";
// import InfiniteScroll from "react-infinite-scroller";
import InfiniteScroll from "react-infinite-scroll-component";
import { setActivePage } from "../Redux/PageSlice";

const AiDigest = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const fetchDarkMode = useSelector((state) => state.darkMode);

  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);

  const isLoggedIn = isLoggedInLocal || isLoggedInRedux;

  const [aiDigestData, setAiDigestData] = useState([]);
  const [comloading, setComLoading] = useState(false);

  const [startValue, setStartValue] = useState(0);
  const [limitValue, setLimitValue] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [apiCallCounter, setApiCallCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  const [hasMore, setHasMore] = useState(true);

  const [aiPollData, setAiPollData] = useState([]);
  const [pollStart, setPollStart] = useState(0);
  const [pollLength, setPollLength] = useState(0);
  const [pollIndicator, setPollIndicator] = useState(0);
  const [visted, setVisted] = useState(0);
  const [vistLength, setVistLength] = useState(0);

  const isFocus = document.hasFocus();

  useEffect(() => {
    if (isFocus) {
      dispatch(setActivePage(false));
      // Add your condition or action here
    }
  }, [isFocus]);

  const INTERVAL_TIME = 30000; // 30 seconds

  useEffect(() => {
    // Update meta tags when the component mounts
    document.title = "AiDigest - GoldDiscovery.com";

    const metaTitle = document.querySelector('meta[name="title"]');
    if (metaTitle) {
      metaTitle.content = "AiDigest - GoldDiscovery.com";
    }

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = "AiDigest - GoldDiscovery.com";
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = "AiDigest - GoldDiscovery.com";
    }

    // // Get darkMode value from local storage
    // const encryptedDarkMode = localStorage.getItem("darkMode");
    // const fetchDarkMode = encryptedDarkMode
    //   ? CryptoJS.AES.decrypt(encryptedDarkMode, SECRET_KEY).toString(
    //       CryptoJS.enc.Utf8
    //     ) === "true"
    //   : false;

    // Set the 'darkmode' class on the body
    const bodyElement = document.querySelector("body");
    if (fetchDarkMode) {
      bodyElement.classList.add("darkmode");
    } else {
      bodyElement.classList.remove("darkmode");
    }

    // Clean up when the component unmounts
    return () => {
      document.title = "GoldDiscovery.com";

      if (metaTitle) {
        metaTitle.content = "GoldDiscovery.com";
      }

      if (metaDescription) {
        metaDescription.content = "";
      }

      if (metaKeywords) {
        metaKeywords.content = "";
      }
    };
  }, []);

  // fetch AI-Digest Page Data
  useEffect(() => {
    const fetchAiDigestPage = async () => {
      try {
        if (!totalCount || totalCount === null) {
          setComLoading(true);
        } else {
          setLoading(true);
        }
        const formData = new FormData();
        formData.append("start", startValue);

        const response = await BaseApi.post(url.aidigest, formData);
        // console.log("Ai-Digest Api:", response);

        if (response.data.data.length > 0) {
          setAiDigestData((prevData) => [...prevData, ...response.data.data]);
          setStartValue(response.data.start);
          setLimitValue(response.data.limit);
          setTotalCount(response.data.count);
          localStorage.setItem("maxId", response.data.max);

          // setCount(response.data.count);
        }
      } catch (error) {
        // console.error("Error fetching Ai-Digest news:", error);
      } finally {
        setComLoading(false);
      }
    };

    fetchAiDigestPage();
  }, []);

  //

  useEffect(() => {
    const fetchAiPoll = async () => {
      try {
        if (aiDigestData.length > 0) {
          const max = localStorage.getItem("maxId");

          // Check if max is not null, undefined, or empty
          if (max) {
            const formData = new FormData();
            formData.append("recId", Number(max));

            const response = await BaseApi.post(url.latest_news, formData);
            // console.log("Polling Ai Api:", response);

            if (response.data && response.data.summaries.length > 0) {
              setAiPollData(response.data.summaries);
              setPollStart(response.data.summaries.length);
              setPollLength(response.data.summaries.length);
              setPollIndicator(response.data.summaries.length);
              setVistLength(response.data.summaries.length);

              localStorage.setItem("maxId", response.data.max);
            }

            // console.log("MaxId", response.data.max);
          }
        }
      } catch (error) {
        // console.error("Error fetching latest news:", error);
      }
    };

    const intervalId = setInterval(() => {
      fetchAiPoll();
    }, INTERVAL_TIME);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [aiDigestData]);

  //

  const navigateToCompanyOverview = (company_url, symbolCode) => {
    dispatch(setSymbolCode(symbolCode));

    const SymbolCode = symbolCode;
    navigate(`/company/${company_url}/${SymbolCode}`);
    window.location.reload();
  };

  const navigateToNewsDetail = (slugurl) => {
    navigate(`/news/${slugurl}`);
    window.location.reload();
  };

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );

    const [datePart, timePart] = formattedDate.split(", ");
    const [month, day, year] = datePart.split("/");
    const formattedDateWithDash = `${year}-${month}-${day}`;

    return `${formattedDateWithDash} ${timePart}`;
  };

  const fetchAiNext = async () => {
    // console.log("FetchAiNext Triggered");
    try {
      if (!totalCount || totalCount === null) {
        setComLoading(true);
      } else {
        setLoading(true);
      }
      const nextValue = Number(startValue) + Number(limitValue) + pollStart;
      const formData = new FormData();
      formData.append("start", nextValue);

      const response = await BaseApi.post(url.aidigest, formData);
      // console.log("Ai-Digest Next Api:", response);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        // console.log(
        //   "start is",
        //   nextValue,
        //   "Response Length",
        //   response.data.data.length,
        //   "Api call Count",
        //   apiCallCounter,
        //   "api Count",
        //   totalCount
        // );
        setAiDigestData((prevData) => [...prevData, ...response.data.data]);
        setStartValue(nextValue - pollLength);

        // Check if nextValue exceeds the total count or apiCallCount exceeds response count
        if (apiCallCounter >= response.data.count) {
          setHasMore(false);
        } else {
          // Increment the apiCallCount if conditions are not met
          setApiCallCounter((prevCount) => prevCount + 1);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      // console.error("Error fetching Ai-Digest news:", error);
    } finally {
      setLoading(false);
    }
  };

  const ref = useRef(null);

  // const ScrollIndicator = () => {
  //   setVisted(visted + pollIndicator);

  //   setTimeout(() => {
  //     setPollIndicator(0);
  //   }, 100);
  // };

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // console.log("poll indicator ", pollIndicator);

      // ScrollIndicator();
    }
  };

  useEffect(() => {
    const handleScroll = (e) => {
      let Top = e.target.scrollTop;
      if (Top == 0) {
        // ScrollIndicator();
        setVisted(vistLength);

        setTimeout(() => {
          setPollIndicator(0);
        }, 100);
      }

      // console.log("scroll Top", e.target.scrollTop);
    };

    ref.current?.addEventListener("scroll", handleScroll);
    return () => ref.current?.removeEventListener("scroll", handleScroll);
  }, [vistLength, visted]);

  return (
    <>
      <Helmet>
        <title>AiDigest - Gold Discovery</title>

        <meta name="title" content="Gold Discovery" />

        <meta name="description" content="AiDigest - GoldDiscovery.com" />
        <meta name="keywords" content="AiDigest - GoldDiscovery.com" />
        <meta property="og:title" content="AiDigest - GoldDiscovery.com" />
        <meta
          property="og:description"
          content="AiDigest - GoldDiscovery.com"
        />
        <meta
          property="og:image"
          content="https://golddiscovery.com/App-icon.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://golddiscovery.com/App-icon.jpg"
        />
      </Helmet>
      <Header />
      <div className="main-content">
        <div className="panel-blk">
          <div className="container">
            <div className="panbxs">
              <SideMenu />
              <>
                {comloading ? (
                  <NewsShimmer />
                ) : (
                  <>
                    <div className="panel_top">
                      <div className="panel-b ai-dig" ref={ref} id="scrollable">
                        <InfiniteScroll
                          next={fetchAiNext}
                          hasMore={hasMore}
                          dataLength={aiDigestData.length}
                          scrollableTarget="scrollable"
                        >
                          {aiDigestData && aiDigestData.length > 0 ? (
                            [...aiPollData, ...aiDigestData].map(
                              (item, index) => (
                                <div className="ne-c" key={index}>
                                  <ul className="lab-head">
                                    <li
                                      onClick={() =>
                                        navigateToCompanyOverview(
                                          item.company_url,
                                          item.symbol_code,
                                          item.company_symbol
                                        )
                                      }
                                    >
                                      <span
                                        className={`label ${
                                          item.symbol_code.endsWith(":AU")
                                            ? "labelau"
                                            : ""
                                        }`}
                                      >
                                        {item.company_symbol}
                                      </span>
                                      <strong>{item.company_name}</strong>
                                    </li>
                                    <li
                                      onClick={() =>
                                        navigateToCompanyOverview(
                                          item.company_url,
                                          item.symbol_code,
                                          item.company_symbol
                                        )
                                      }
                                    >
                                      <span>
                                        {formatDate(item.datetime)} (ET)
                                      </span>
                                      <span> ${item.price}</span>
                                    </li>
                                  </ul>
                                  <p
                                    onClick={() =>
                                      navigateToNewsDetail(item.slug_url)
                                    }
                                  >
                                    {item.news_summary}
                                  </p>
                                </div>
                              )
                            )
                          ) : (
                            <div className="nodat">
                              <p>Currently No News Available</p>
                            </div>
                          )}

                          {loading && (
                            <div className="load_spinner">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          )}
                        </InfiniteScroll>

                        {aiDigestData && aiDigestData.length > 0 && (
                          <div
                            className="uparw"
                            onClick={(e) => {
                              e.preventDefault();
                              scrollToTop();
                            }}
                          >
                            <a>
                              <img src="images/garw.svg"></img>
                              {pollIndicator - visted > 0 && (
                                <span>{pollIndicator - visted}</span>
                              )}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AiDigest;

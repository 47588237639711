import { createSlice } from "@reduxjs/toolkit";

const PageSlice = createSlice({
  name: "activePage",

  initialState: true,
  reducers: {
    setActivePage: (state, action) => {
      return action.payload;
    },
  },
});

export const { setActivePage } = PageSlice.actions;
export default PageSlice.reducer;
